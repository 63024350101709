.mint-container {
  width: 580px; /* 幅を50%に設定 */
  margin: 0 auto; /* 中央に配置 */
  margin-bottom: 10px;
  border-radius: 10px;
  border-color: black;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
}

/* 横並びのためのフレックスボックス設定 */
.mint-container > div {
  display: flex;
  justify-content: center; /* 中央揃え */
  align-items: center; /* 垂直方向も中央揃え */
}

.mint-controls, .credit-card-container {
  margin: 10px; /* 余白を設定 */
}

.mint-controls {
  margin-bottom: 20px;
}

.credit-card-container {
  margin-top: 20px;
}

.controls-button {
    width: 40px; /* 幅を％で指定 */
    height: 40px; /* 高さを％で指定 */
    border-radius: 50%;
    border: none;
    background-color: #212071;
    box-shadow: 0px 4px 8px rgba(223, 223, 223, 0.1);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1.5em;
    margin: 0 10px; /* ボタン間の距離を広げる */
    }

    .mint-amount {
      font-size: x-large;
    }
    
    .controls-button:hover {
    background-color:rgb(255, 223, 63);
    transform: scale(1.2); /* ホバー時に少し大きく */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    
    .credit-card-button {
      display: flex; /* フレックスコンテナに */
      align-items: center; /* 垂直方向の中央揃え */
      justify-content: center; /* 水平方向の中央揃え */
      background-color: rgb(229, 73, 26);
      width: 200px;
      height: 35px;
      color: white;
      padding: 12px 24px;
      border-radius: 12px;
      text-decoration: none;
      transition: background-color 0.3s;
      font-weight: bold; /* 太字に設定 */
    
    }
    
    .credit-card-button:hover {
      background-color: #89d66f;
    }

    /* 例えば、以下のようなスタイルを定義 */
  .scrollTopButton {
    position: fixed; /* ページの特定の位置に固定 */
    right: 20px; /* 右から20pxの位置に */
    bottom: 20px; /* 下から20pxの位置に */
    padding: 10px 15px; /* パディング */
    background-color: #f0f0f0; /* 背景色 */
    border: none; /* ボーダーなし */
    cursor: pointer; /* カーソルをポインターに */
    border-radius: 5px; /* 角丸 */
    font-size: 14px; /* フォントサイズ */
  }

  .scrollTopButton:hover {
    background-color: #e0e0e0; /* ホバー時の背景色 */
  }

@media (max-width: 768px) {
  .main-contents {
    padding: 20px;
  }


  .mint-container {
    width: 100%; /* 画面幅いっぱいに広げる */
    padding: 5px; /* パディングを少し減らす */
  }

  .credit-card-button {
    width: 170px; /* ボタンの幅を縮める */
    font-size: 1em; /* フォントサイズを小さく */
  }


  .scrollTopButton {
    right: 10px; /* 位置を少し変更 */
    bottom: 10px;
  }
}
