.footer {
    margin-top: 30px;
    padding: 20px;
}


@media (max-width: 768px) {
 .footer a {
    font-size: 15px;
}
}