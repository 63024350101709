.App {
  font-family: 'Arial', sans-serif;
  background-color: rgb(254,253,222);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1.6;
}

.backToTopContainer {
  display: flex; /* フレックスボックスを使用 */
  justify-content: center; /* 中央揃え */
  margin: 20px 0; /* 上下の余白 */
}

.backToTopButton {
  background-color: #9ed984;
  width: 200px;
  height: 40px;
  color: white;
  border-radius: 12px;
  transition: background-color 0.3s;
  font-size: large;
  font-weight: bold; /* 太字に設定 */
  border: none;
  cursor: pointer; /* カーソルスタイル */

}

.backToTopButton:hover {
  transform: scale(1.1); /* ホバー時に少し大きく */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #89d66f;
}

/* デフォルトのスタイル */
.responsive-iframe {
  width: 600px; /* 幅を600pxに設定 */
  height: 600px; /* 高さを600pxに設定 */
  max-width: 600px; /* 最大幅を600pxに設定 */
  max-height: 600px; /* 最大高さを600pxに設定 */
  text-align: center;
}

/* スライダーのスタイル */
.slick-slide {
  margin-right: 50px; /* スライダー間の距離を50pxに設定 */
}

@media (max-width: 768px) {
  .slick-slide {
    margin: 0 10px;
  }

  .responsive-iframe {
    height: 400px; /* 高さを400pxに調整 */
    max-width: 100%; /* 最大幅を100%に調整 */
  }
}

/* ビューポート幅が480px未満の場合のスタイル */
@media (max-width: 480px) {
/* スライダーのスタイル */
.slick-slide {
  margin-right: 5px; /* スライダー間の距離を50pxに設定 */
}

  .responsive-iframe {
    width: 300px; /* 高さを400pxに調整 */
    height: 600px; /* 高さを300pxに調整 */
  }

  .backToTopContainer {
    margin: 10px; /* 上下の余白 */
  }
}

/* App.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


