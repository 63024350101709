/* スライダースタイル */

.iframe-slider {
    margin: 0 auto; /* 中央揃え */
    max-width: 1800px; /* コンテナの最大幅を設定 */
}

.slick-track {
  display: flex; /* Flexboxを使用してスライドを横に並べる */
  align-items: center; /* スライドの垂直方向の中央揃え */
  padding: 0 15px; /* スライド間のパディングを調整 */

  padding-top: 50px;
  padding-bottom: 50px;
  height: 300px; /* 例として300pxに設定。実際の内容に合わせて調整が必要 */
  overflow: hidden; /* オーバーフローするコンテンツを非表示に */
}

.slick-slide {
    display: flex; /* Flexboxを使用 */
    justify-content: center; /* 中央に配置 */
    /* 他のスタイルはそのまま */
  }

.iframe-slider .slick-slide > div {
    display: flex; /* Flexboxを使用 */
    justify-content: center; /* 中央に配置 */
  
    flex-shrink: 0; /* スライド要素が縮小しないように設定 */
  }
  
  .iframe-slider .iframe-card-container {
    width: 100%;
    margin-bottom: 0;
  }

/* カードスタイル */

  .card {
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin: auto; /* 中央寄せ */
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    width: 80%; /* カードの幅を調整（例として80%に設定） */
    max-height: 250px; /* カードの最大高さを増やす */
}
  
  .card:hover, .card:active {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .card img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .card a {
    text-decoration: none; /* 下線を取り除く */
    color: black; /* フォントカラーを黒に設定 */
  }
  
  .card-content h3 {
    margin-top: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px; /* タイトルのフォントサイズ */
    padding-bottom: 5px; /* タイトルの下の余白を調整 */
  }

  .card-content p {
    font-size: 14px; /* TokenIDのフォントサイズを小さく */
    font-weight: bold;
  }
  
  .card-content {
    padding: 5px 10px;
    text-align: center;
  }

    .card-content h3,
    .card-content p {
    margin: 0; /* タイトルとTokenIDの間隔を詰める */
    padding: 0;
    }

  /* セレクター */

  .slider-container {
    display: flex;
    align-items: center;
    justify-content: center; /* 中央揃えに設定 */
    margin: 0 auto; /* 必要に応じて中央揃え */
    max-width: 1000px; /* コンテナの最大幅 */
  }


  .selected-card {
    flex-grow: 1; /* コンテンツの高さを伸ばす */
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto; /* 中央配置 */
    width: 400px; 
    height: 600px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .card.selected {
    transform: scale(1.2); /* 選択されたカードを少し大きくする */
    transition: transform 0.3s ease; /* スムーズなトランジション */
  }
  
  .selected-image-container img {
    flex-grow: 1; /* コンテナの幅を最大にする */

    width: 100%; /* 画像の大きさを調整 */
    max-width: 600px; /* 最大幅を設定 */
    margin: 10px; /* ボタンとの間隔 */
  }
  
  .mint-button {
    display: flex; /* フレックスコンテナに */
    align-items: center; /* 垂直方向の中央揃え */
    justify-content: center; /* 水平方向の中央揃え */
    background-color: #9ed984;
    width: 200px;
    height: 40px;
    color: white;
    padding: 12px 24px;
    border-radius: 12px;
    text-decoration: none;
    transition: background-color 0.3s;
    font-size: large;
    font-weight: bold; /* 太字に設定 */
    margin: 10px;
    border: none;

  }

  .mint-button:hover {
    transform: scale(1.1); /* ホバー時に少し大きく */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #89d66f;
  }

    /* スマートフォン表示時のアローボタンのスタイル */
    .slider-button {
    display: inline-block;
    background-color: #007bff; /* ボタンの背景色 */
    color: white; /* ボタンの文字色 */
    border: none; /* 枠線を消す */
    border-radius: 50%; /* 円形にする */
    width: 80px; /* ボタンの幅 */
    height: 80px; /* ボタンの高さ */
    line-height: 40px; /* テキストを垂直中央に配置 */
    font-size: x-large;
    text-align: center; /* テキストを水平中央に配置 */
    margin: 0 50px; /* ボタン間の余白 */
    cursor: pointer; /* カーソルを指に変更 */
    transition: background-color 0.3s, transform 0.3s; /* 背景色と変形のアニメーション */
    z-index: 5; /* 必要に応じてz-indexを設定 */
  }

  .slider-button:hover {
    color: #000;
    background-color: #e0e0e0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  }

  /* スマートフォン表示時のアローボタンのスタイル */
    .mobile-slider .slider-button {
    display: inline-block;
    background-color: #007bff; /* ボタンの背景色 */
    color: white; /* ボタンの文字色 */
    border: none; /* 枠線を消す */
    border-radius: 50%; /* 円形にする */
    width: 40px; /* ボタンの幅 */
    height: 40px; /* ボタンの高さ */
    line-height: 40px; /* テキストを垂直中央に配置 */
    text-align: center; /* テキストを水平中央に配置 */
    margin: 0 10px; /* ボタン間の余白 */
    cursor: pointer; /* カーソルを指に変更 */
    transition: background-color 0.3s, transform 0.3s; /* 背景色と変形のアニメーション */
  }
  
  /* ホバー時のスタイル */
  .mobile-slider .slider-button:hover {
    background-color: #0056b3; /* ホバー時の背景色 */
    transform: scale(1.1); /* 少し大きくする */
  }
  
  /* スマホ表示の際の指示文 */
.mobile-instruction {
    text-align: center;
    margin-top: 10px;
  }

  .selected-card p, .selected-card h3 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 5px 0;
  }

  /* メディアクエリ */
  
  /* スマートフォン表示時の調整 */
  @media (max-width: 768px) {

/* スライダースタイル */

.slider-button {
    width: 40px; /* ボタンの幅 */
    height: 40px; /* ボタンの高さ */
  }

.slick-track {
    width: 768px;
    height: 180px; /* 例として300pxに設定。実際の内容に合わせて調整が必要 */
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden; /* オーバーフローするコンテンツを非表示に */
}

.slick-slide {
    width: auto; /* スライドの幅を自動に調整 */
    margin: 10px; /* スライド間の余白を調整 */
  }

.selected-image-container {
    padding: 10px; /* 余白の調整 */
  }

  .selected-card {
    width: 90%; /* 幅を画面幅に応じて調整 */
    height: auto; /* 高さを内容に応じて自動調整 */
    padding: 20px; /* パディングを増やして余白を調整 */
  }

  .selected-card img {
    max-width: 100%; /* 画像の最大幅を調整 */
    height: auto; /* 画像の高さを自動調整 */
  }

  .mint-button {
    width: 100%; /* ボタンの幅を親要素に合わせる */
    height: auto;
    margin-top: 20px; /* ボタンの上の余白を調整 */
    font-size: x-large;
  }

    .iframe-slider .slick-slide > div {
        margin: 0 5px; /* 両サイドのマージンを小さく調整 */
    }
    .iframe-slider .iframe-card-container {
        width: auto; /* 幅を自動に設定 */
        max-width: 100%; /* 最大幅を100%に設定 */
    }

    .iframe-card-container {
        margin: 5px;
        width: 100%; /* スライドの幅を100%に設定 */
        padding: 0 15px; /* 左右に10pxのパディングを設定 */
      }

/* カードスタイル */
    .card {
        width: 60px;
        height: 150px;
        max-height: 110px; /* スマートフォン時のカードの高さを増やす */
        max-height: 150px; 
        margin: 5px;
        padding: 5px;
    }

    .card img {
        width: 100px;
        height: 100px;
    }

    .card-content h3 {
        font-size: 14px;
    }

    .card-content {
        font-size: 12px;
    }

  }
  