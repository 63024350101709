.app-header {
    background-color: rgba(240, 236, 175, 0.5);
    color: #fff;
    padding: 0.4rem 0;
    position: relative;
    height: 80px;
  }
  
  .app-header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  
  .logo-container {
    padding-left: 20px;
    margin-right: auto;
  }
  
  .header-content {
    padding-right: 20px;
    margin-left: auto;
  }
  
  .app-header .logo {
    color: #fff;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  
  .app-header .logo img {
    height: 2rem;
    margin-right: 0.5rem;
    transition: height 0.3s ease;
  }
  
  .container img {
    width: 150px;
    height: auto;
  }
  
  .navi-container {
    display: flex;
    gap: 30px;
    align-items: center;
    padding-right: 0.5rem;
    justify-content: center;
  }
  
  .navi-image {
    width: 70px !important;
    height: 70px !important;
    animation: navi-move 3s infinite alternate ease-in-out;
  }
  
  @media (max-width: 1080px) {
    .header-content {
      display: none;
    }
  
    .logo-container, .navi-container {
      display: flex;
      margin: 0 auto;
    }
  
    .navi-container {
      position: static;
      transform: none;
      gap: 10px;
    }
  }
  
  @media (max-width: 768px) {
    .app-header .logo img {
      height: 1.5rem;
    }
  
    .navi-container {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  
    .navi-image {
      width: 10px;
      height: 10px;
      animation: navi-move-small 3s infinite alternate ease-in-out;
    }
  
    .navi-container .navi-image:nth-child(3),
    .navi-container .navi-image:nth-child(4) {
      display: none;
    }
  }
  
  @keyframes navi-move {
    from {
      transform: translateX(-10px);
    }
    to {
      transform: translateX(10px);
    }
  }
  
  @keyframes navi-move-small {
    from {
      transform: translateX(-5px);
    }
    to {
      transform: translateX(5px);
    }
  }
  